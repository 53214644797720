










































































































































import {
  reactive,
  computed,
  defineComponent,
  PropType,
  onMounted,
  watch,
} from '@vue/composition-api'
import { vvHasError, vvGetError, vvValidate } from 'src/util/vee_validate'
import { Workplace } from 'src/models/workplace'
import { Company } from 'src/models/company'
import companyApi from 'src/apis/company'
import InviteInfo, { InviteState } from './InviteInfo.vue'
import WorkplaceSelector, { WorkplaceWithSelection } from './WorkplaceSelector.vue'
import ConfirmModal from './ConfirmModal.vue'
import usersWorkplaceApi from 'src/apis/users_workplace'
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook'

type RegisterState = {
  familyName: string
  firstName: string
  companyId: number | null
  jobTitle: string
  password: string
  passwordConfirmation: string
}

const initialRegisterState = (): RegisterState => {
  return {
    familyName: '',
    firstName: '',
    companyId: null,
    jobTitle: '',
    password: '',
    passwordConfirmation: '',
  }
}

type State = {
  companies: Company[]
  inviteState: InviteState
  workplacesWithSelection: WorkplaceWithSelection[],
  registerState: RegisterState
  showConfirmModal: boolean
}

export default defineComponent({
  components: {
    InviteInfo,
    WorkplaceSelector,
    ConfirmModal
  },
  props: {
    inviteState: {
      type: Object as PropType<InviteState>,
      default: () => {}
    },
    workplacesWithSelection: {
      type: Array as PropType<WorkplaceWithSelection[]>,
      default: () => []
    },
  },
  setup(props, { root, emit }) {
    const state = reactive<State>({
      companies: [],
      inviteState: { ...props.inviteState },
      workplacesWithSelection: [ ...props.workplacesWithSelection ],
      registerState: initialRegisterState(),
      showConfirmModal: false,
    })

    watch(
      state.workplacesWithSelection,
      async() => {
        await fetchCompanies()
        // 現在の選択が新たな一覧に存在しなければクリアする
        if (
          state.registerState.companyId != null &&
            !state.companies.some(company => company.id === state.registerState.companyId)
        ) {
          state.registerState.companyId = null
        }
      },
      { deep: true }
    )

    const selectedWorkplaces = computed<Workplace[]>(() => {
      return state.workplacesWithSelection.filter(workplace => workplace.isSelected)
    })

    const isAnySelected = computed<boolean>(() => {
      return state.workplacesWithSelection.some(workplace => workplace.isSelected)
    })

    const hasError = computed(() => {
      return vvHasError(root)
    })

    const getError = (fieldName: string): string | null => {
      return vvGetError(root, fieldName)
    }

    const updateInviteInfo = (fieldName: keyof InviteState, value: string) => {
      state.inviteState[fieldName] = value
    }

    const openConfirmModalWithValidation = async() => {
      if (!(await vvValidate(root))) { return }
      openConfirmModal()
    }

    const openConfirmModal = () => {
      state.showConfirmModal = true
    }

    const closeConfirmModal = () => {
      state.showConfirmModal = false
    }

    const registerUserAndBulkInvite = async() => {
      if (!state.registerState.companyId) { return }

      const selectedWorkplaceIds = state.workplacesWithSelection.filter(w => w.isSelected).map(w => w.id)
      try {
        await usersWorkplaceApi.bulkCreateWithNewUser({
          role: state.inviteState.role,
          workplace_ids: selectedWorkplaceIds,
          sp_key: localStorage.getItem('sp'),
          user: {
            email: state.inviteState.email,
            family_name: state.registerState.familyName,
            first_name: state.registerState.firstName,
            company_id: state.registerState.companyId,
            job_title: state.registerState.jobTitle,
            password: state.registerState.password,
          }
        })
        notifySuccess1(root, 'ユーザーを登録し、センターに招待しました')
        emit('completed')
      } catch (err: any) {
        const errId = 'ERR00002'
        const msg = `ユーザー登録とセンターへの招待に失敗しました。` +
          `管理者に連絡してください。` +
          `(ERR: センター選択 ${errId})`
        notifyError1(root, msg, { err })
      }
    }

    const fetchCompanies = async() => {
      const workplaceIds = selectedWorkplaces.value.map(workplace => workplace.id)
      const { data: companies } = await companyApi.shareableCompanies({ workplace_ids: workplaceIds })
      state.companies = companies
    }

    const ruleStr = {
      required: true,
      max: 255,
      noForbiddenChars: true,
    }
    const validations = {
      familyName: ruleStr,
      firstName: ruleStr,
      company: { required: true },
      jobTitle: ruleStr,
      password: {
        required: true,
        min: 8,
      },
      passwordConfirmation: {
        required: true,
        confirmed: 'password',
      },
    }

    onMounted(() => {
      fetchCompanies()
    })

    return {
      state,
      selectedWorkplaces,
      isAnySelected,
      hasError,
      getError,
      openConfirmModalWithValidation,
      updateInviteInfo,
      closeConfirmModal,
      registerUserAndBulkInvite,
      validations,
    }
  }
})
