








































































import Vue from 'vue'
import { defineComponent, computed, reactive, SetupContext, PropType } from '@vue/composition-api'
import { vvHasError, vvGetError, vvValidate } from 'src/util/vee_validate'
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook'
import NonEditableCard from 'src/views/Dashboard/Workplace/Data/NonEditableCard.vue'
import { StaffTag } from 'src/models/workplaceMasters/staffTag'
import staffsStaffTagApi from 'src/apis/workplace_masters/staffs_staff_tag'

type State = {
  tagIds: number[]
}

export default defineComponent({
  name: 'StaffTagBulkCreateModal',
  components: {
    NonEditableCard,
  },
  props: {
    tags: {
      type: Array as PropType<StaffTag[]>,
      default: () => []
    },
    staffIds: {
      type: Array as PropType<number[]>,
      required: true,
    },
  },
  setup(props, context: SetupContext) {
    const root = context.root as Vue
    const workplaceId = root.$route.params.workplaceId.toString()

    const state = reactive<State>({
      tagIds: [],
    })

    const hasError = computed(() => {
      return vvHasError(root)
    })

    const getError = (fieldName: string): string | null => {
      return vvGetError(root, fieldName)
    }

    const save = async() => {
      if (!(await vvValidate(root, 'tagIds'))) { return }

      try {
        await staffsStaffTagApi.bulkCreate(
          workplaceId,
          {
            staff_ids: props.staffIds,
            staff_tag_ids: state.tagIds
          }
        )
        context.emit('completed')
        notifySuccess1(root, 'スタッフタグを一括追加しました。')
      } catch (err: any) {
        const msg = `スタッフタグの一括追加に失敗しました。` +
          `管理者に連絡してください。(ERR: スタッフタグ ERR00004)`
        notifyError1(root, msg, { err })
      }
    }

    const validationSchema = {
      tagIds: { required: true },
    }

    return {
      props,
      state,
      hasError,
      getError,
      save,
      validationSchema,
      workplaceId,
    }
  }
})
