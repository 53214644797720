


























































import Vue from 'vue'
import { reactive, computed, defineComponent, PropType, SetupContext } from '@vue/composition-api'
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook'
import { StaffTag } from 'src/models/workplaceMasters/staffTag'
import staffTagApi from 'src/apis/workplace_masters/staff_tag'

type State = {
  isConfirmed: boolean,
}

export default defineComponent({
  props: {
    staffTag: {
      type: Object as PropType<StaffTag>,
      required: true,
    },
  },
  setup(props, context: SetupContext) {
    const root = context.root as Vue
    const workplaceId = root.$route.params.workplaceId.toString()

    const state = reactive<State>({
      isConfirmed: false,
    })

    const hasStaffAssociation = computed<boolean>(() => {
      return props.staffTag.staffIds.length > 0
    })
    const canDelete = computed<boolean>(() => {
      return !hasStaffAssociation.value || state.isConfirmed
    })
    const deleteStaffTag = async() => {
      try {
        await staffTagApi.delete(workplaceId, props.staffTag.id)
        context.emit('completed')
        notifySuccess1(root, `スタッフタグを削除しました`)
      } catch (err: any) {
        const errId = 'ERR00003'
        const msg = `スタッフタグの削除に失敗しました。` +
          `管理者に連絡してください。` +
          `(ERR: スタッフタグ ${errId})`
        notifyError1(root, msg, { err })
      }
    }

    return {
      state,
      hasStaffAssociation,
      canDelete,
      deleteStaffTag,
      workplaceId,
    }
  }
})
