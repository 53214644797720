
































































import Vue from 'vue'
import { reactive, computed, defineComponent, SetupContext, PropType } from '@vue/composition-api'
import { vvHasError, vvGetError, vvValidate } from 'src/util/vee_validate'
import { StaffTag } from 'src/models/workplaceMasters/staffTag'
import { NO_FORBIDDEN_CHARS_MESSAGE } from 'src/consts'
import staffTagApi from 'src/apis/workplace_masters/staff_tag'
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook'

export type SaveCandidate = {
  value: string
}

type State = {
  saveCandidate: SaveCandidate
}

export default defineComponent({
  props: {
    originalStaffTag: {
      type: Object as PropType<StaffTag | null>,
      default: null
    },
  },
  setup(props, context: SetupContext) {
    const root = context.root as Vue
    const workplaceId = root.$route.params.workplaceId.toString()

    const state = reactive<State>({
      saveCandidate: props.originalStaffTag ? { ...props.originalStaffTag } : { value: '' }
    })

    const isNew = computed(() => !props.originalStaffTag)

    const hasError = computed(() => {
      return vvHasError(root)
    })

    const getError = (fieldName: string): string | null => {
      return vvGetError(root, fieldName)
    }

    const save = async() => {
      if (!(await vvValidate(root))) { return }

      const operationName = isNew.value ? '作成' : '編集'

      try {
        if (isNew.value) {
          await staffTagApi.create(workplaceId, state.saveCandidate)
        } else {
          await staffTagApi.update(workplaceId, props.originalStaffTag!.id, state.saveCandidate)
        }
        context.emit('completed')
        notifySuccess1(root, `スタッフタグを${operationName}しました`)
      } catch (err: any) {
        const errStatus = err.response.status
        const errRes = err.response.data || {}
        if (errStatus === 400 && errRes.reason === 'duplicated_value') {
          const msg = `そのスタッフタグ名は既に使用されています。`
          notifyError1(root, msg, { timeout: 5 * 1000 })
        } else {
          const errId = 'ERR00001'
          const msg = `スタッフタグの${operationName}に失敗しました。` +
            `管理者に連絡してください。` +
            `(ERR: スタッフタグ ${errId})`
          notifyError1(root, msg, { err })
        }
      }
    }

    const ruleStr = {
      required: true,
      max: 255,
      noForbiddenChars: true,
      noForbiddenCharsForStaffTag: true
    }
    const validationSchema = {
      value: ruleStr,
    }

    return {
      state,
      isNew,
      validationSchema,
      getError,
      hasError,
      save,
      NO_FORBIDDEN_CHARS_MESSAGE,
      workplaceId,
    }
  }
})
