import axios from 'axios'
import * as utils from 'src/apis/apiUtils'
import { StringOrNumber } from 'src/models/common'
import { BulkCreateParams } from 'src/models/api/workplaceMasters/staffsStaffTagRequest'

export default {
  async bulkCreate(workplaceId: StringOrNumber, params: BulkCreateParams): Promise<void> {
    await axios.post(
      utils.getApiUrl(`/masters/workplaces/${workplaceId}/staffs_staff_tags/bulk_create`),
      params,
    )
  },
}
