





































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { Workplace } from 'src/models/workplace'

export type WorkplaceWithSelection = Workplace & {
  isSelected: boolean
}

export default defineComponent({
  props: {
    value: {
      type: Array as PropType<WorkplaceWithSelection[]>,
      default: () => []
    },
  },
  setup(props, { emit }) {
    const workplaces = computed({
      get: () => props.value,
      set: (newVal: WorkplaceWithSelection[]) => {
        emit('input', newVal)
      }
    })

    const selectAll = () => {
      workplaces.value.forEach(workplace => { workplace.isSelected = true })
      emit('input', workplaces.value)
    }

    const deselectAll = () => {
      workplaces.value.forEach(workplace => { workplace.isSelected = false })
      emit('input', workplaces.value)
    }

    return {
      workplaces,
      selectAll,
      deselectAll,
    }
  }
})
