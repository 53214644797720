

























import { computed, defineComponent, PropType } from '@vue/composition-api'
import { Workplace } from 'src/models/workplace'

type User = {
  familyName: string
  firstName: string
  email: string
}

export default defineComponent({
  props: {
    user: {
      type: Object as PropType<User>,
      default: () => {}
    },
    workplaces: {
      type: Array as PropType<Workplace[]>,
      default: () => []
    },
    withUserRegistration: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const workplaceNames = computed<string>(() => {
      return props.workplaces.map(workplace => workplace.name).join(', ')
    })

    return {
      workplaceNames,
    }
  }
})
