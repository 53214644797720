import axios from 'axios'
import { getApiUrl } from './apiUtils'
import {
  CheckUserExistsParams,
  BulkCreateParams,
  BulkCreateWithUserParams,
} from 'src/models/api/UsersWorkplaceRequest'
import { CheckUserExistsResponse, UsersWorkplaceResponse } from 'src/models/api/UsersWorkplaceResponse'
import { UsersWorkplace } from 'src/models/UsersWorkplace'

export default {
  async checkUserExists(reqParams :CheckUserExistsParams): Promise<CheckUserExistsResponse> {
    const { data: resData } = await axios.get(getApiUrl('/users_workplaces/check_user_exists'), { params: reqParams })
    return resData
  },
  async bulkCreate(reqParams :BulkCreateParams): Promise<UsersWorkplace[]> {
    const { data: resData } = await axios.post(getApiUrl('/users_workplaces/bulk_create'), reqParams)
    return resData.map(convertResponseToUserWorkplace)
  },
  async bulkCreateWithNewUser(reqParams :BulkCreateWithUserParams): Promise<UsersWorkplace[]> {
    const { data: resData } = await axios.post(getApiUrl('/users_workplaces/bulk_create_with_new_user'), reqParams)
    return resData.map(convertResponseToUserWorkplace)
  },
}

function convertResponseToUserWorkplace(response: UsersWorkplaceResponse): UsersWorkplace {
  return {
    id: response.id,
    userId: response.user_id,
    workplaceId: response.workplace_id,
    role: response.role,
    status: response.status,
  }
}
