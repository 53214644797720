import axios, { AxiosResponse } from 'axios'
import { getApiUrl } from './apiUtils'
import {
  Company,
  companiesResponseToCompanies
} from 'src/models/company'
import { ShareableCompaniesParams } from 'src/models/api/companyRequest'
import { CompaniesResponse } from 'src/models/api/companyResponse'

const apis = {
  index(): Promise<AxiosResponse<CompaniesResponse>> {
    return axios.get(getApiUrl(`/companies`))
  },
  shareableCompanies(reqParams: ShareableCompaniesParams): Promise<AxiosResponse<CompaniesResponse>> {
    return axios.get(getApiUrl(`/companies/shareable_companies`), { params: reqParams })
  },
}

export const apisWithTransformedData = {
  index(): Promise<Company[]> {
    return apis.index().then(({ data }) => companiesResponseToCompanies(data))
  },
  shareableCompanies(reqParams: ShareableCompaniesParams): Promise<Company[]> {
    return apis.shareableCompanies(reqParams).then(({ data }) => companiesResponseToCompanies(data))
  },
}

export default apis
