import axios from 'axios'
import * as utils from 'src/apis/apiUtils'
import { StringOrNumber } from 'src/models/common'
import { StaffTag } from 'src/models/workplaceMasters/staffTag'
import { StaffTagResponse } from 'src/models/api/workplaceMasters/staffTagResponse'
import { CreateParams, UpdateParams } from 'src/models/api/workplaceMasters/staffTagRequest'

export default {
  async index(workplaceId: StringOrNumber): Promise<StaffTag[]> {
    const { data: resData } = await axios.get(
      utils.getApiUrl(`/masters/workplaces/${workplaceId}/staff_tags`),
    )
    return resData.map(convertResponseToStaffTag)
  },
  async create(workplaceId: StringOrNumber, data: CreateParams): Promise<StaffTag> {
    const { data: resData } = await axios.post(
      utils.getApiUrl(`/masters/workplaces/${workplaceId}/staff_tags`),
      data
    )
    return convertResponseToStaffTag(resData)
  },
  async update(workplaceId: StringOrNumber, staffTagId: StringOrNumber, data: UpdateParams): Promise<StaffTag> {
    const { data: resData } = await axios.put(
      utils.getApiUrl(`/masters/workplaces/${workplaceId}/staff_tags/${staffTagId}`),
      data
    )
    return convertResponseToStaffTag(resData)
  },
  async delete(workplaceId: StringOrNumber, staffTagId: StringOrNumber): Promise<StaffTag> {
    const { data: resData } = await axios.delete(utils.getApiUrl(`/masters/workplaces/${workplaceId}/staff_tags/${staffTagId}`))
    return convertResponseToStaffTag(resData)
  },
  async bulkUpdateDispOrder(workplaceId: StringOrNumber, staffTags: StaffTag[]): Promise<StaffTag[]> {
    const { data: resData } = await axios.post(
      utils.getApiUrl(`/masters/workplaces/${workplaceId}/staff_tags/bulk_update_disp_order`),
      { items: staffTags }
    )
    return resData.map(convertResponseToStaffTag)
  },
}

function convertResponseToStaffTag(response: StaffTagResponse): StaffTag {
  return {
    id: response.id,
    value: response.value,
    dispOrder: response.disp_order,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
    createdByName: response.created_by_name,
    updatedByName: response.updated_by_name,
    staffIds: response.staff_ids,
  }
}
