



















































import { defineComponent, PropType } from '@vue/composition-api'
import { vvGetError } from 'src/util/vee_validate'

export type InviteState = {
  email: string
  emailConfirm: string
  role: string
}

export const initialInviteState = () => {
  const inviteRoleCandidates = window.master.lovs.user_role.vals_inuse
  return {
    email: '',
    emailConfirm: '',
    role: inviteRoleCandidates.length > 0 ? inviteRoleCandidates[0].key : '',
  }
}

export default defineComponent({
  props: {
    inviteState: {
      type: Object as PropType<InviteState>,
      default: () => {}
    },
  },
  setup(_, { root }) {
    const inviteRoleCandidates = window.master.lovs.user_role.vals_inuse

    const validations = {
      // vee-validateのemailはマルチバイト文字OKなので、ここでは正規表現でマルチバイト文字を禁止する
      // UTF-8における1Byte文字(\u0000-\u007f)でない文字を含まないことを確認する
      email: { required: true, email: true, regex: '^[\u0000-\u007f]+$' },
      emailConfirm: { required: true, confirmed: 'email' },
    }

    const getError = (fieldName: string): string | null => {
      return vvGetError(root, fieldName)
    }

    return {
      inviteRoleCandidates,
      validations,
      getError,
    }
  }
})
