









































































































import {
  reactive,
  computed,
  defineComponent,
  SetupContext
} from '@vue/composition-api'
import Vue from 'vue'
import { userHasWorkplaceRoleGteOwnerSync } from 'src/hooks/appRoleHook'
import { StaffTag } from 'src/models/workplaceMasters/staffTag'
import staffTagApi from 'src/apis/workplace_masters/staff_tag'
import { notifyError1 } from 'src/hooks/notificationHook'
import { setPageName } from 'src/hooks/displayPageNameHook'
import Draggable from 'vuedraggable'
import StaffTagModal from './modals/StaffTagModal.vue'
import DeleteModal from './modals/DeleteModal.vue'
import { wrappedMapGetters } from 'src/hooks/storeHook'
import moment from 'src/util/moment-ja'

const displayPageName = 'スタッフタグ'
const msgVars = {
  update_disp_order: '表示順変更',
}
const dragOptions = {
  handle: '.grabbable',
  animation: 300,
  chosenClass: 'bg-color-default',
}
type State = {
  isLoadingCsv: boolean,
  isTableFixed: boolean,
  staffTags: StaffTag[],
  selectedStaffTag: StaffTag | null,
  showStaffTagModal: boolean,
  showDeleteModal: boolean,
  editingStaffTag: StaffTag | null,
  deletingStaffTag: StaffTag | null,
  useCreateAndUpdateInfo: boolean,
  useStaffTag: boolean,
}

export default defineComponent({
  components: {
    Draggable,
    StaffTagModal,
    DeleteModal,
  },
  setup(_props, context: SetupContext) {
    const root = context.root as Vue
    setPageName(root, displayPageName)

    const state: State = reactive({
      ...wrappedMapGetters(root.$store, 'workplace', [
        'useStaffTag',
        'useCreateAndUpdateInfo',
      ]),
      isLoadingCsv: false,
      isTableFixed: true,
      staffTags: [],
      selectedStaffTag: null,
      showStaffTagModal: false,
      showDeleteModal: false,
      editingStaffTag: null,
      deletingStaffTag: null,
    })
    const openStaffTagModal = (staffTag: StaffTag | null = null) => {
      state.editingStaffTag = staffTag
      state.showStaffTagModal = true
    }
    const closeStaffTagModal = () => {
      state.showStaffTagModal = false
      state.editingStaffTag = null
    }
    const handleSaveCompleted = () => {
      fetchStaffTags()
      closeStaffTagModal()
    }
    const openDeleteModal = (staffTag: StaffTag) => {
      state.deletingStaffTag = staffTag
      state.showDeleteModal = true
    }
    const closeDeleteModal = () => {
      state.showDeleteModal = false
      state.deletingStaffTag = null
    }
    const handleDeleteCompleted = () => {
      fetchStaffTags()
      closeDeleteModal()
    }
    const updateDispOrder = async() => {
      const opType = 'update_disp_order'
      try {
        const staffTags = state.staffTags.map((staffTag, index) => ({ ...staffTag, disp_order: index + 1 }))
        await staffTagApi.bulkUpdateDispOrder(workplaceId, staffTags)
        fetchStaffTags()
      } catch (err: any) {
        const errId = 'ERR00004'
        const msg = `${displayPageName}の${msgVars[opType]}に失敗しました。` +
          `管理者に連絡してください。` +
          `(ERR: ${displayPageName} ${errId})`
        notifyError1(root, msg, { err })
      }
    }
    const isSelectedStaffTag = (staffTag: StaffTag) => {
      if (!state.selectedStaffTag) {
        return false
      }
      return state.selectedStaffTag.id === staffTag.id
    }
    const selectStaffTag = (staffTag: StaffTag) => {
      state.selectedStaffTag = staffTag
    }
    const fetchStaffTags = async() => {
      const staffTags = await staffTagApi.index(workplaceId)
      state.staffTags = staffTags
    }
    const workplaceId = root.$route.params.workplaceId.toString()
    const isGteOwner = computed(() => userHasWorkplaceRoleGteOwnerSync(root, Number(workplaceId)))
    const hasStaffTags = computed<boolean>(() => state.staffTags.length > 0)
    const rowWidth = computed<number>(() => 577 + Number(state.useCreateAndUpdateInfo) * 640)
    return {
      displayPageName,
      state,
      openStaffTagModal,
      closeStaffTagModal,
      handleSaveCompleted,
      openDeleteModal,
      closeDeleteModal,
      handleDeleteCompleted,
      updateDispOrder,
      isSelectedStaffTag,
      selectStaffTag,
      fetchStaffTags,
      dragOptions,
      isGteOwner,
      hasStaffTags,
      rowWidth,
      moment,
      workplaceId,
    }
  },
  mounted() {
    this.fetchStaffTags()
  },
})

