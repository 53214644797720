































































import {
  reactive,
  computed,
  defineComponent,
  PropType,
} from '@vue/composition-api'
import { vvHasError, vvValidate } from 'src/util/vee_validate'
import InviteInfo, { InviteState, initialInviteState } from './InviteInfo.vue'
import WorkplaceSelector, { WorkplaceWithSelection } from './WorkplaceSelector.vue'
import usersWorkplaceApi from 'src/apis/users_workplace'
import { Workplace } from 'src/models/workplace'
import ConfirmModal from './ConfirmModal.vue'
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook'
import { CheckUserExistsResponse } from 'src/models/api/UsersWorkplaceResponse'
import * as C from 'src/consts'

type State = {
  inviteState: InviteState
  workplacesWithSelection: WorkplaceWithSelection[]
  showConfirmModal: boolean
  user: CheckUserExistsResponse['user']
}

export default defineComponent({
  components: {
    InviteInfo,
    WorkplaceSelector,
    ConfirmModal
  },
  props: {
    workplaces: {
      type: Array as PropType<Workplace[]>,
      default: () => []
    },
  },
  setup(props, { root, emit }) {
    const state = reactive<State>({
      inviteState: initialInviteState(),
      workplacesWithSelection: props.workplaces.filter(workplace => {
        return workplace.user_role === C.USER_WORKPLACE_ROLE_W_OWNER
      }).map(workplace => ({
        ...workplace,
        isSelected: false
      })),
      showConfirmModal: false,
      user: null,
    })

    const selectedWorkplaces = computed<Workplace[]>(() => {
      return state.workplacesWithSelection.filter(workplace => workplace.isSelected)
    })

    const isAnySelected = computed<boolean>(() => {
      return state.workplacesWithSelection.some(workplace => workplace.isSelected)
    })

    const hasError = computed(() => {
      return vvHasError(root)
    })

    const updateInviteInfo = (fieldName: keyof InviteState, value: string) => {
      state.inviteState[fieldName] = value
    }

    const checkUserExists = async() => {
      if (!(await vvValidate(root))) { return }

      const response = await usersWorkplaceApi.checkUserExists({ email: state.inviteState.email })
      if (!response.exists) {
        const msg = `そのEメールで登録済のユーザーが見つかりませんでした。`
        notifyError1(root, msg, { timeout: 5 * 1000 })
        emit('user-not-found', state.inviteState, state.workplacesWithSelection)
        return
      }

      state.user = response.user
      openConfirmModal()
    }

    const openConfirmModal = () => {
      state.showConfirmModal = true
    }

    const closeConfirmModal = () => {
      state.showConfirmModal = false
    }

    const bulkInvite = async() => {
      const selectedWorkplaceIds = state.workplacesWithSelection.filter(w => w.isSelected).map(w => w.id)
      try {
        await usersWorkplaceApi.bulkCreate({
          email: state.inviteState.email,
          role: state.inviteState.role,
          workplace_ids: selectedWorkplaceIds,
          sp_key: localStorage.getItem('sp'),
        })
        notifySuccess1(root, 'ユーザーをセンターに招待しました')
        emit('completed')
      } catch (err: any) {
        const errStatus = err.response.status
        const errRes = err.response.data || {}
        if (errStatus === 400 && errRes.reason === 'dup_email') {
          const msg = `そのEメールは選択された全てのセンターに招待済みです。` +
            `招待しなおす場合は、一度削除してから、再度招待をしてください`
          notifyError1(root, msg, { timeout: 5 * 1000 })
        } else {
          const errId = 'ERR00002'
          const msg = `センターへの招待に失敗しました。` +
            `管理者に連絡してください。` +
            `(ERR: センター選択 ${errId})`
          notifyError1(root, msg, { err })
        }
      }
    }

    return {
      state,
      selectedWorkplaces,
      isAnySelected,
      hasError,
      checkUserExists,
      updateInviteInfo,
      closeConfirmModal,
      bulkInvite,
    }
  }
})
